<template>
  <div class="wrapper w-full">
    <header class="home-page header" :class="{ 'open-menu': openedSidebar }">
      <div class="content content_in">
        <div class="header__menu" @click="openedSidebar = !openedSidebar">
          <div class="icon-menu" :class="{ active: openedSidebar }">
            <div class="sw-topper"></div>
            <div class="sw-bottom"></div>
            <div class="sw-footer"></div>
          </div>
        </div>
        <router-link to="/" class="land-header__logo" aria-label="return to main page">
          <img :src="require('@/assets/images/entry/logo-white.svg')" alt="site logo" />
        </router-link>
        <div class="header__manager">
          <div class="manager">
            <div class="manager__item">
              <a href="https://telegram.me/Robert_ph" target="_blank" class="manager__link">@Robert_ph</a>
            </div>
            <div class="manager__item pl-0">
              <a href="https://telegram.me/Leo31PH" target="_blank" class="manager__link">@Leo31PH</a>
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a href="https://telegram.me/publisher_push_house" target="_blank" class="manager__link">@publisher_push_house</a>
            </div>
          </div>
        </div>
        <nav class="header__entry entry-menu">
          <ul>
            <li>
              <router-link to="/login" class="btn _entry">{{ $t("landing.login") }}</router-link>
            </li>
            <li>
              <router-link to="/registration" class="btn _registration">{{ $t("landing.register") }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="header__lang language">
          <select-language class="land-select" />
        </div>

        <router-link to="/login" class="header__login">
          <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.25 20.2552H21.75C25.7541 20.2552 29 23.494 29 27.4892C29 28.2882 28.3508 28.936 27.55 28.936C26.8064 28.936 26.1935 28.3775 26.1098 27.6579L26.0926 27.2342C25.9652 25.0401 24.2045 23.2833 22.0056 23.1562L21.75 23.1488H7.25C4.84756 23.1488 2.9 25.0921 2.9 27.4892C2.9 28.2882 2.25081 28.936 1.45 28.936C0.649187 28.936 0 28.2882 0 27.4892C0 23.5991 3.07734 20.4262 6.93551 20.2619L7.25 20.2552H21.75H7.25ZM14.5 0C19.3049 0 23.2 3.88653 23.2 8.68081C23.2 13.4751 19.3049 17.3616 14.5 17.3616C9.69512 17.3616 5.8 13.4751 5.8 8.68081C5.8 3.88653 9.69512 0 14.5 0ZM14.5 2.89359C11.2967 2.89359 8.69995 5.48461 8.69995 8.6808C8.69995 11.877 11.2967 14.468 14.5 14.468C17.7032 14.468 20.3 11.877 20.3 8.6808C20.3 5.48461 17.7032 2.89359 14.5 2.89359Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </header>
    <section class="offer" @click="selectingCountry = false">
      <div class="content">
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="offer__text">
              <div class="offer__slogan">
                {{ $t("landing.help_making_cash") }}
              </div>
              <h1 v-html="$t('landing.header')"></h1>
              <p>
                {{ $t("landing.platform") }}
              </p>
            </div>
            <div class="offer-direction">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="offer-direction__item _webmaster">
                    <h2>{{ $t("landing.webmaster") }}</h2>
                    <div class="offer-direction__text">
                      {{ $t("landing.create_platform") }} <br />
                      {{ $t("landing.set_code_upload_files") }} <br />
                      {{ $t("landing.get_80_percent") }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="offer-direction__item _arbitrator">
                    <h2>{{ $t("landing.arbitrage") }}</h2>
                    <div class="offer-direction__text">
                      {{ $t("landing.create_platform") }} <br />
                      {{ $t("landing.select_lending") }} <br />
                      {{ $t("landing.launch_traffic") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="c-calculate">
              <div class="calculate">
                <div class="calculate__content">
                  <div class="calculate__title">
                    {{ $t("landing.calculate_income") }}
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-item pb-1">
                        <div class="form-item__field">
                          <label class="radio-text-icon d-block" @click="device = 'Phone'">
                            <input type="radio" name="device" checked />
                            <span class="radio-text-icon__visible"><span class="icon-mobile2"></span> Mobile</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-item pb-1">
                        <div class="form-item__field">
                          <label class="radio-text-icon d-block" @click="device = 'PC'">
                            <input type="radio" name="device" />
                            <span class="radio-text-icon__visible"><span class="icon-desktop2"></span> Desktop</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-item">
                        <div class="form-item__field">
                          <div class="dropdown bootstrap-select w100 _select-country dropup" :class="{ show: selectingCountry }">
                            <button type="button" @click.stop="selectingCountry = true" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown">
                              <div class="filter-option">
                                <div class="filter-option-inner">
                                  <div class="filter-option-inner-inner">
                                    {{ !selectedCountry.country_name_en ? $t("landing.choose_country") : $i18n.locale === "ru" ? selectedCountry.country_name_ru : selectedCountry.country_name_en }}
                                  </div>
                                </div>
                              </div>
                            </button>
                            <div class="dropdown-menu" :class="{ show: selectingCountry }">
                              <div class="bs-searchbox">
                                <input @click.stop="selectingCountry = true" type="search" v-model="searchCountry" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-2" aria-autocomplete="list" />
                              </div>
                              <div class="inner show" role="listbox" id="bs-select-2" tabindex="-1">
                                <ul class="dropdown-menu inner show" role="presentation">
                                  <li v-for="(country, index) in filteredCounties" :key="index">
                                    <a
                                      @click="
                                        selectedCountry = country;
                                        selectingCountry = false;
                                      "
                                      role="option"
                                      class="dropdown-item"
                                      ><span class="text">{{ $i18n.locale === "ru" ? country.country_name_ru : country.country_name_en }}</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-item">
                        <div class="form-item__label text-center">
                          {{ $t("landing.traffic_amount") }}
                        </div>
                        <div class="form-item__field">
                          <div class="form-range-traffic">
                            <span style="position: absolute">
                              <span id="trafficValue" class="p-0"></span>
                            </span>
                            <div class="range-input">
                              <input v-model="trafficValue" type="range" min="1000" step="1000" max="1000000" value="134567" id="trafficRange" aria-label="traffic range" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="calculate__label text-center">
                    {{ $t("landing.average_income") }}
                  </div>
                  <div class="calculate__total text-center">≈ ${{ websiteIncome.toFixed(2) }}</div>
                </div>
              </div>
              <div class="calculate-caption icon-i">
                {{ $t("landing.calc_info") }}
              </div>
            </div>
          </div>
        </div>
        <div class="mouse-animation"></div>
      </div>
    </section>
    <section class="benefits">
      <div class="content">
        <h2 class="title">{{ $t("landing.why_we") }}</h2>
        <div class="row _offset0">
          <div class="col-6 col-lg-3 d-flex" v-for="(benefit, index) in benefits" :key="index">
            <div class="benefit">
              <div class="benefit__icon">
                <img :src="benefit.image" alt="" />
              </div>
              <div class="benefit__value">{{ benefit.value }}</div>
              <div class="benefit__desc">{{ formatLabel(benefit.info) }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="formats">
      <div class="content">
        <h2 class="title">{{ $t("landing.ad_formats_title") }}</h2>
        <ul class="formats__tubs">
          <li>
            <a @click.prevent="currentFormat = 0" href="#" :class="{ active: currentFormat === 0 }">
              <span class="icon-format-1"></span>
              Push Notifications
            </a>
          </li>
          <li>
            <a @click.prevent="currentFormat = 1" href="#" :class="{ active: currentFormat === 1 }">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 23" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.62919 0.235352C5.78766 0.235352 4.34075 1.702 4.34075 3.51196V3.69743C3.02537 4.14728 2.1046 5.36006 2.1046 6.78725V6.97272C0.920764 7.42258 0 8.63667 0 10.0625V18.7966C0 20.6053 1.44692 22.0719 3.28844 22.0719H18.5468C20.3884 22.0719 21.8353 20.6053 21.8353 18.7966V10.0625C21.8353 8.63667 20.9145 7.42258 19.5991 6.97272V6.78725C19.5991 5.36006 18.6784 4.14728 17.4945 3.69743V3.51196C17.4945 1.702 15.9161 0.235352 14.2061 0.235352H7.62919ZM16.3105 5.69582V5.69582H15.2582H6.44514H5.39284V5.69582C4.86669 5.69582 4.34054 6.18514 4.34054 6.78759H17.4943C17.4943 6.18514 16.9682 5.69582 16.3105 5.69582ZM6.44642 3.51219H15.2595C15.2595 2.90844 14.7333 2.42043 14.2072 2.42043H7.63026C6.97257 2.42043 6.44642 2.90844 6.44642 3.51219ZM18.5465 8.97104V8.97104C19.0726 8.97104 19.5988 9.46036 19.5988 10.0628V18.7969C19.5988 19.3994 19.0726 19.8887 18.5465 19.8887H3.2881C2.63041 19.8887 2.10426 19.3994 2.10426 18.7969V10.0628C2.10426 9.46036 2.63041 8.97104 3.2881 8.97104V8.97104H4.3404H17.4942H18.5465Z"
                  fill="currentColor"
                />
              </svg>
              In-Page
            </a>
          </li>
          <li>
            <a @click.prevent="currentFormat = 2" href="#" :class="{ active: currentFormat === 2 }">
              <span class="icon-format-3"></span>
              On-Click
            </a>
          </li>
          <li>
            <a @click.prevent="currentFormat = 3" href="#" :class="{ active: currentFormat === 3 }">
              <span class="icon-format-4"></span>
              Native
            </a>
          </li>
        </ul>
        <div class="tubs-content">
          <div class="tubs-content__item" :class="{ active: currentFormat === format.id }" v-for="(format, index) in formats" :key="index">
            <div class="formats__item">
              <div class="row flex-row-reverse">
                <div class="col-12 col-lg-8">
                  <div class="formats-preview">
                    <div class="formats-preview__body">
                      <img :src="format.image" alt="" class="formats-preview-img" />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4 align-self-center">
                  <div class="formats__text">
                    <h3>{{ format.name }}</h3>
                    <p>{{ formatLabel(format.info) }}</p>
                    <ul v-for="option in formats[index].listOptions" :key="option">
                      <li>{{ formatLabel(option) }}</li>
                    </ul>
                  </div>
                  <div class="formats__btn">
                    <button @click="$router.push('/login')" class="btn _big">
                      {{ $t("landing.try") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="price">
      <div class="content">
        <div class="row align-items-center flex-row-reverse">
          <div class="col-12 col-lg-5 d-flex">
            <div class="price__text">
              <div class="price__before-title">
                {{ $t("landing.max_income") }}
              </div>
              <h2 class="title text-md-left">Web Push</h2>
              <ul class="price__list">
                <li>
                  <p><strong>Revshare</strong></p>
                  <p>
                    {{ $t("landing.revshare_option") }}
                  </p>
                </li>
                <li>
                  <p><strong>CPL</strong></p>
                  <p>
                    {{ $t("landing.cpl_option") }}
                  </p>
                </li>
              </ul>
              <div class="price__btn _pc">
                <button @click="$router.push('/login')" class="btn _big">
                  {{ $t("landing.join") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7">
            <div class="price__slider">
              <div class="price__slider-item">
                <div class="c-table">
                  <div class="inner">
                    <table class="table _no-wrap" role="grid">
                      <thead>
                        <tr>
                          <th>
                            <strong>{{ $t("dashboard.table.country") }}</strong>
                          </th>
                          <th><strong>Revshare</strong></th>
                          <th><strong>CPL</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="table-platform"></div>
                          </td>

                          <td>
                            <div class="table-platform">
                              <span class="icon-mobile2"></span>
                              <span class="table-sep">|</span>
                              <span class="icon-desktop2"></span>
                            </div>
                          </td>
                          <td>
                            <div class="table-platform">
                              <span class="icon-mobile2"></span>
                              <span class="table-sep">|</span>
                              <span class="icon-desktop2"></span>
                            </div>
                          </td>
                        </tr>
                        <tr v-for="(country, index) in countries" :key="index">
                          <td>
                            <div class="country font-weight-bold">
                              <gb-flag :code="country.iso" height="22px" width="32px" />
                              {{ country.iso }}
                            </div>
                          </td>

                          <td>
                            $ {{ country.avg_price_mob || 0 }}
                            <span class="table-sep">|</span>
                            {{ country.avg_price_pc }}
                          </td>
                          <td>
                            $ {{ country.cpl_price_mob || 0 }}
                            <span class="table-sep">|</span>
                            {{ country.cpl_price_pc || 0 }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center flex-row-reverse justify-content-between pt-1">
              <div class="col-12 col-md-4">
                <div class="slider-controls justify-content-md-end">
                  <button class="btn" @click="showPrevious" :disabled="currentPage == 0" title="show previous slide">
                    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.587736 4.59696C0.192522 4.31807 0.192523 3.73196 0.587737 3.45308L4.84072 0.45196C5.30442 0.12475 5.94431 0.456377 5.94431 1.0239L5.94431 7.02613C5.94431 7.59366 5.30442 7.92528 4.84072 7.59807L0.587736 4.59696Z" fill="currentColor" />
                    </svg>
                  </button>
                  <div class="slider-controls__pagination">
                    <span>{{ currentPage + 1 }}</span
                    >/15
                  </div>
                  <button :disabled="currentPage == 14" class="btn" @click="showNext" title="show next slide">
                    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.41226 4.59696C5.80748 4.31807 5.80748 3.73196 5.41226 3.45308L1.15928 0.45196C0.695583 0.12475 0.0556942 0.456377 0.0556942 1.0239L0.0556945 7.02613C0.0556945 7.59366 0.695585 7.92528 1.15928 7.59807L5.41226 4.59696Z" fill="currentColor" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-7 col-md-8">
                <div class="price__caption">
                  {{ $t("landing.table_hint") }}
                </div>
              </div>
            </div>
            <div class="price__btn _mobile">
              <button @click="$router.push('/login')" class="btn _big">
                {{ $t("landing.join") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="start">
      <div class="content">
        <div class="start__content">
          <div class="row flex-row-reverse">
            <div class="col-12 col-lg-6">
              <div class="start__desc">
                <h2>{{ $t("landing.get_start") }}</h2>
                <p>
                  {{ $t("landing.where2start") }}
                </p>
                <a :href="guideLink" target="_blank" class="btn _bg-green _big"> {{ $t("landing.watch_guide") }}</a>
              </div>
            </div>
            <div class="col-12 col-lg-6 align-self-end">
              <div class="start__thumb">
                <img src="../assets/images/home/start.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="payments">
      <div class="content">
        <div class="banners">
          <div class="banners-slider" v-for="(banner, index) in banners" :key="index">
            <div class="banners-slider__item" v-show="currentBanner === index">
              <a :href="banner.href" target="_blank">
                <div class="banner-item" :class="`slide-${index}`">
                  <h3>{{ formatLabel(banner.title) }}</h3>
                  <p>{{ formatLabel(banner.text) }}</p>
                  <span>{{ formatLabel(banner.btn) }}</span>
                </div>
                <picture>
                  <span @click.prevent.stop="setSlider('prev')" class="prev">
                    <svg width="12" height="12" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.587736 4.59696C0.192522 4.31807 0.192523 3.73196 0.587737 3.45308L4.84072 0.45196C5.30442 0.12475 5.94431 0.456377 5.94431 1.0239L5.94431 7.02613C5.94431 7.59366 5.30442 7.92528 4.84072 7.59807L0.587736 4.59696Z" fill="#fff" />
                    </svg>
                  </span>
                  <span @click.prevent.stop="setSlider('next')" class="next">
                    <svg width="12" height="12" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.41226 4.59696C5.80748 4.31807 5.80748 3.73196 5.41226 3.45308L1.15928 0.45196C0.695583 0.12475 0.0556942 0.456377 0.0556942 1.0239L0.0556945 7.02613C0.0556945 7.59366 0.695585 7.92528 1.15928 7.59807L5.41226 4.59696Z" fill="#fff" />
                    </svg>
                  </span>
                  <source :srcset="banner.mob" media="(max-width: 768px)" />
                  <img :src="banner.image" alt="" />
                </picture>
              </a>
            </div>
          </div>
        </div>
        <div class="payments__list">
          <div class="row _offset14">
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/webmoney.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/capitalist.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/cards.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/tether.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/qiwi.svg" alt="" />
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <div class="payment-item">
                <img src="../assets/images/home/payment/youmoney.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="footer__top">
        <div class="content">
          <div class="row justify-content-between">
            <div class="col-12 col-lg-2 col-md-3">
              <div class="footer__logo">
                <img src="../assets/images/entry/logo-white.svg" alt="" width="155" />
                <span>By <a href="https://push.house/" target="_blank">Push.House</a></span>
              </div>
            </div>
            <div class="col-12 col-lg-10 col-md-9 pl-lg-5">
              <div class="footer__contacts">
                <ul class="contacts-list">
                  <li>
                    <a href="https://telegram.me/Leo31PH" target="_blank" class="icon-telegram2">Leo31PH</a>
                  </li>
                  <li>
                    <a href="https://telegram.me/Robert_ph" target="_blank" class="icon-telegram2">Robert_ph</a>
                  </li>
                  <!-- <li>
                    <a href="skype:live:.cid.856afe451230469c?chat" target="_blank" class="icon-skype">Pushhouse</a>
                  </li> -->
                  <li>
                    <a href="mailto:push.house.partner@gmail.com" class="icon-email2">push.house.partner@gmail.com</a>
                  </li>
                </ul>
              </div>
              <div class="footer__desc" v-html="$t('landing.footer_content')"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="content">
          <div class="row align-items-center justify-content-between flex-row-reverse">
            <div class="col-12 col-md-auto">
              <ul class="footer__menu">
                <li>
                  <router-link to="/terms-of-use">{{ $t("landing.terms_of_use") }}</router-link>
                </li>
                <li>
                  <router-link to="/privacy">{{ $t("landing.privacy_policy") }}</router-link>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-auto">
              <div class="footer__copyright">© Partners.House, 2019 - 2024</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <aside class="land-sidebar" :class="{ active: openedSidebar }">
      <div class="sidebar__content mt-2">
        <select-language leftMenu />

        <nav class="sidebar__entry entry-menu">
          <ul>
            <li>
              <router-link to="/login" class="btn _entry">{{ $t("landing.login") }}</router-link>
            </li>
            <li>
              <router-link to="/registration" class="btn _registration">{{ $t("landing.register") }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="sidebar__manager">
          <div class="manager">
            <div class="manager__item">
              <div class="manager__icon icon-telegram align-center"></div>
              <div class="manager__label">{{ $t("landing.manager") }}</div>
              <a href="https://telegram.me/Leo31PH" target="_blank" class="manager__link">@Leo31PH</a>
            </div>
            <div class="manager__item">
              <div class="manager__icon icon-telegram align-center"></div>
              <div class="manager__label">{{ $t("landing.manager") }}</div>
              <a href="https://telegram.me/Robert_ph" target="_blank" class="manager__link">@Robert_ph</a>
            </div>
            <div class="manager__chat">
              <div class="manager__chat-label">chat</div>
              <a href="https://telegram.me/publisher_push_house" target="_blank" class="manager__link">@publisher_push_house</a>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <div class="menu-bg" @click="openedSidebar = false" :class="{ active: openedSidebar }"></div>
    <alert v-if="$alert.title" />
    <div class="cookies-block"  v-if="showCookiePopup && isInEEZ">
      <small
        >{{ $t("landing.cookies_text") }} <router-link to="/privacy">{{ $t("landing.click_here") }}</router-link></small
      >
      <div class="actions">
        <button class="btn _bg-green btn-accept" @click="closeCookiePopup(true)">
          {{ $t("landing.accept") }}
        </button>
        <button class="btn btn-reject" @click="closeCookiePopup(false)">
          {{ $t("landing.reject") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dashboardService from "@/api/dashboardService";
import ServicePanel from "@/api/servicePanel";
import SelectLanguage from "@/components/SelectLanguage.vue";
import { mapActions } from "vuex";

export default {
  beforeMount() {
    document.title = `Partners.House`;
  },
  beforeUpdate() {
    document.title = `Partners.House`;
  },
  name: "Landing",
  components: {
    SelectLanguage,
  },
  data() {
    return {
      isInEEZ: false,
      showCookiePopup: true,
      loaded: null,
      searchCountry: "",
      device: "Phone",
      trafficValue: 0,
      openedSidebar: false,
      selectingCountry: false,
      selectedCountry: {},
      allCountries: [],
      countries: [],
      currentPage: 0,
      banners: [
        {
          id: 0,
          title: "landing.banner_ph_title",
          text: "landing.banner_ph_text",
          btn: "landing.banner_ph_btn",
          href: "https://push.house/",
          image: require("@/assets/images/home/banner/banner-ph.webp"),
          mob: require("@/assets/images/home/banner/banner-ph-mob.webp"),
        },
        {
          id: 1,
          title: "landing.banner_clo_title",
          text: "landing.banner_clo_text",
          btn: "landing.banner_ph_btn",
          href: "https://cloaking.house/",
          image: require("@/assets/images/home/banner/banner-clo.webp"),
          mob: require("@/assets/images/home/banner/banner-clo-mob.webp"),
        },
        {
          id: 2,
          title: "landing.banner_cpa_title",
          text: "landing.banner_cpa_text",
          btn: "landing.banner_cpa_btn",
          href: "https://cpa.house/",
          image: require("@/assets/images/home/banner/banner-cpa.webp"),
          mob: require("@/assets/images/home/banner/banner-cpa-mob.webp"),
        },
        {
          id: 3,
          title: "landing.banner_spy_title",
          text: "landing.banner_spy_text",
          btn: "landing.banner_spy_btn",
          href: "https://spy.house/",
          image: require("@/assets/images/home/banner/banner-spy.webp"),
          mob: require("@/assets/images/home/banner/banner-spy-mob.webp"),
        },
      ],
      currentFormat: 0,
      currentBanner: 0,
      benefits: [
        {
          value: "700M+",
          info: "landing.daily_see",
          image: require("@/assets/images/home/benefits/benefit-1.svg"),
        },
        {
          value: "8",
          info: "landing.withdraw_systems",
          image: require("@/assets/images/home/benefits/benefit-2.svg"),
        },
        {
          value: "99.9%",
          info: "landing.ads_view",
          image: require("@/assets/images/home/benefits/benefit-3.svg"),
        },
        {
          value: "24/7",
          info: "support.title",
          image: require("@/assets/images/home/benefits/benefit-4.svg"),
        },
        {
          value: "185+",
          info: "landing.monetize_geo",
          image: require("@/assets/images/home/benefits/benefit-5.svg"),
        },
        {
          value: "4",
          info: "landing.ad_formats",
          image: require("@/assets/images/home/benefits/benefit-6.svg"),
        },
        {
          value: "2",
          info: "landing.payment_methods",
          image: require("@/assets/images/home/benefits/benefit-7.svg"),
        },
        {
          value: "3%",
          info: "landing.ref_system",
          image: require("@/assets/images/home/benefits/benefit-8.svg"),
        },
      ],
      formats: [
        {
          name: "Push Notifications",
          id: 0,
          image: require("@/assets/images/home/formats/push.webp"),
          info: "landing.push_info",
          listOptions: ["landing.push_list1", "landing.push_list2", "landing.push_list3", "landing.push_list4"],
        },
        {
          name: "In-Page",
          id: 1,
          image: require("@/assets/images/home/formats/in-page.webp"),
          info: "landing.inPage_title",
          listOptions: ["landing.inPage_list1", "landing.inPage_list2", "landing.inPage_list3", "landing.inPage_list4"],
        },
        {
          name: "On-click",
          id: 2,
          image: require("@/assets/images/home/formats/onclick.webp"),
          info: "landing.onClick_title",
          listOptions: ["landing.onClick_list1", "landing.onClick_list2", "landing.onClick_list3", "landing.onClick_list4"],
        },
        {
          name: "Native",
          id: 3,
          image: require("@/assets/images/home/formats/native.webp"),
          info: "landing.native_title",
          listOptions: ["landing.native_list1", "landing.native_list2", "landing.native_list3", "landing.native_list4"],
        },
      ],
      interval: null,
    };
  },
  watch: {
    openedSidebar(val) {
      if (val) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  computed: {
    filteredCounties() {
      let countryName = this.$i18n.locale == "ru" ? "country_name_ru" : "country_name_en";
      if (this.searchCountry === "") return this.allCountries;

      return this.allCountries.filter((row) => {
        return row[countryName].toLowerCase().includes(this.searchCountry.toLocaleLowerCase()) || row.iso.toLocaleLowerCase().includes(this.searchCountry.toLocaleLowerCase());
      });
    },
    websiteIncome() {
      if (this.selectedCountry.iso) {
        if (this.device === "PC") {
          return (this.selectedCountry.avg_price_pc * +this.trafficValue) / 10;
        } else {
          return (this.selectedCountry.avg_price_mob * +this.trafficValue) / 10;
        }
      } else {
        return 0;
      }
    },
    guideLink() {
      let sublink = "";
      if (this.$i18n.locale === "ru") sublink = "?lang=ru";
      // else if (this.$i18n.locale === "uk") sublink = "?lang=uk";

      return `https://guide.partners.house${sublink}`;
    },
  },
  mounted() {
   
    if (localStorage.getItem("consentMode")) {
      this.showCookiePopup = false;
    }
    this.checkUserZone()

    if (this.$route.query.secret) {
      ServicePanel.verifyWallet({ secret: this.$route.query.secret }).then((res) => {
        if (res && res.data.status === 200)
          this.$alert({
            title: this.$t("payment_change"),
            type: "success",
            time: 5000,
          });
      });
    }

    if (this.$route.query && this.$route.query?.rid) {
      this.setUserReferral(this.$route.query.rid);
      localStorage.setItem("userPid", this.$route.query.rid);
    }
    if (this.$route.params && this.$route.params?.id) {
      this.setUserReferral(this.$route.params.id);
      localStorage.setItem("userPid", this.$route.params.id);
    }

    if (this.$route.query) {
      for (const key in this.$route.query) {
        if (key.startsWith("utm")) {
          const utmParams = this.$route.fullPath.match(/\butm[^&]*/g);
          localStorage.setItem("utm_params", utmParams);
        }
      }
    }

    dashboardService.getAllCounties().then((res) => {
      if (res && res.status === 200) {
        this.allCountries = res.data.push;
        this.countries = this.allCountries.slice(0, 10);
      }
    });

    this.interval = setInterval(() => {
      if (this.currentBanner === 3) {
        this.currentBanner = 0;
        return;
      }
      this.currentBanner++;
    }, 5000);

    let myRange = document.querySelector("#trafficRange");
    let myValue = document.querySelector("#trafficValue");

    let off = myRange.offsetWidth / (parseInt(myRange.max) - parseInt(myRange.min));
    let px = (myRange.valueAsNumber - parseInt(myRange.min)) * off - myValue.offsetParent.offsetWidth / 2;

    myValue.parentElement.style.left = px + 10 + "px";
    myValue.parentElement.style.top = myRange.offsetHeight + "px";
    myValue.innerHTML = myRange.value;

    myRange.oninput = function () {
      let px = (myRange.valueAsNumber - parseInt(myRange.min)) * off - myValue.offsetWidth / 2;
      myValue.innerHTML = myRange.value;
      myValue.parentElement.style.left = px - 15 + "px";
    };
  },

  methods: {
    ...mapActions({
      setUserReferral: "user/setUserReferral",
    }),
     async checkUserZone(){
      if (!this.showCookiePopup) return false;
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
       
        const europeanEconomicZoneCountries = [
          "Austria",
          "Belgium",
          "Bulgaria",
          "Croatia",
          "Cyprus",
          "Czechia",
          "Denmark",
          "Estonia",
          "Finland",
          "France",
          "Germany",
          "Greece",
          "Hungary",
          "Iceland",
          "Ireland",
          "Italy",
          "Latvia",
          "Liechtenstein",
          "Lithuania",
          "Luxembourg",
          "Malta",
          "Netherlands",
          "Norway",
          "Poland",
          "Portugal",
          "Romania",
          "Slovakia",
          "Slovenia",
          "Spain",
          "Sweden",
        ];
        this.isInEEZ = europeanEconomicZoneCountries.includes(data.country_name);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    },
    closeCookiePopup(val) {
       const grantedCookies = {
          ad_storage: val ? "granted": 'denied',
          analytics_storage: val ? "granted": 'denied',
          ad_user_data: val ? "granted": 'denied',
          ad_personalization:val ? "granted": 'denied',
          personalization_storage:val ? "granted": 'denied',
          functionality_storage:val ? "granted": 'denied',
          security_storage: val ? "granted": 'denied',
        }   
      if (val === true && window.gtag) {   
        window.gtag("consent", "update",grantedCookies);
      }
      localStorage.setItem("consentMode", JSON.stringify(grantedCookies));
       
      this.showCookiePopup = false;
    },
    showPrevious() {
      if (this.currentPage == 1) {
        this.currentPage = 0;
        this.countries = this.allCountries.slice(0, 10);
        return;
      }
      this.currentPage -= 1;
      let current = this.currentPage * 10;
      let prev10 = this.currentPage * 10 + 10;

      this.countries = this.allCountries.slice(current, prev10);
    },
    setSlider(val) {
      if (val === "prev") {
        this.currentBanner === 0 ? (this.currentBanner = this.banners.length - 1) : this.currentBanner--;
      } else if (val === "next") {
        this.currentBanner === this.banners.length - 1 ? (this.currentBanner = 0) : this.currentBanner++;
      }
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (this.currentBanner === 3) {
          this.currentBanner = 0;
          return;
        }
        this.currentBanner++;
      }, 5000);
    },
    showNext() {
      this.currentPage += 1;
      let current = this.currentPage * 10;
      let next10 = this.currentPage * 10 + 10;
      this.countries = this.allCountries.slice(current, next10);
    },
    formatLabel(el) {
      return this.$t(el);
    },
    close() {
      if (!this.openedSidebar) {
        return;
      }
      this.openedSidebar = false;
    },
  },
};
</script>

<style scoped>
@import "../assets/css/landing.css";
</style>

<style lang="scss" scoped>
.cookies-block {
  animation: slideup 0.3s;
  transition: 0.3s;
  display: flex;
  background: #161824;
  border: 1px solid #272a37;
  border-radius: 15px;
  padding: 20px 30px;
  position: fixed;
  bottom: 20px;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 999;
  small {
    color: #b3b3b3;
    font-size: 15px;
    padding-right: 40px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    a {
      color: #ffffff;
      text-decoration: underline;
      text-decoration-style: dashed;
    }
  }
  .actions {
    display: flex;
    column-gap: 8px;
  }
}

@media screen and (max-width: 768px) {
  .cookies-block {
    display: block;
    .actions {
      margin-top: 10px;
    }
  }
}

@keyframes slideup {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.tubs-content__item {
  display: none;
}
.tubs-content__item.active {
  display: block;
}

.land-sidebar {
  width: 240px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 191;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1920px) {
  .land-sidebar {
    left: 50%;
    transform: translateX(-960px);
  }
}
@media screen and (max-width: 1200px) {
  .land-sidebar {
    width: 280px;
    transform: translateX(-100%);
    transition: ease-out 0.3s;
  }
  .land-sidebar.active {
    transition: ease-in 0.4s;
    transform: translateX(0);
  }
}
@media screen and (min-width: 1200px) {
  .land-sidebar {
    display: none;
  }
}

.header__logo {
  display: block;
}
.header__logo img {
  display: block;
  width: 144px;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .header__logo {
    display: block;
  }
}

.prev {
  position: absolute;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  left: 0;
  width: 40px;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    left: 50%;
  }
}

.next {
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  right: 0;
  width: 40px;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    left: 50%;
  }
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
}

.icon-desktop2:before {
  content: "\e939";
}

.icon-format-1:before {
  content: "\e93a";
}

.icon-format-2:before {
  content: "\e93b";
}

.icon-format-3:before {
  content: "\e93c";
}

.icon-format-4:before {
  content: "\e93d";
}

.icon-i:before {
  content: "\e93e";
}

.icon-mobile2:before {
  content: "\e93f";
}

.icon-email2:before {
  content: "\e942";
}

.icon-telegram2:before {
  content: "\e92b";
}

.icon-skype:before {
  content: "\e92e";
}

.icon-telegram:before {
  content: "\e917";
}
</style>
